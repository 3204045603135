import React from 'react';

import './UserInboxSidebar.scss';
import UserInbox from '../UserInbox/UserInbox.jsx';
import { getString } from '../../../helpers/styles';
import Bell from '../../../shared/assets/icons/redesign/bell.svg';

export default function UserInboxSidebar({
  user,
  NextLink,
  taskList,
  inboxSidebar,
  addUserEvent,
  refetchUserData,
  notificationList,
  showInboxSidebar,
  markNotificationRead
}) {
  return (
    <sidebar
      className={getString([{
        value: 'userInboxSidebar',
        condition: true
      }, {
        value: 'userInboxSidebar-visible',
        condition: inboxSidebar
      }])}
    >
      <div className="userInboxSidebar-header">
        <div
          className="userInboxSidebar-notifications-icon-wrap"
        >
          <Bell className="userInboxSidebar-notifications-icon" />
          <span className="userInboxSidebar-notifications-count">
            {user.username ?
              taskList.length + notificationList.length : 1}
          </span>
        </div>

        <div
          className="userInboxSidebar-close-button"
          onClick={() => showInboxSidebar(false)}>
          &times;
        </div>
      </div>

      <div className="userInboxSidebar-body">
        <UserInbox
          taskList={taskList}
          NextLink={NextLink}
          addUserEvent={addUserEvent}
          notificationList={notificationList}
          markNotificationRead={markNotificationRead}
          userInboxNotificationsRefetch={refetchUserData}
        />
      </div>
    </sidebar>
  );
}
