import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';

import './UserInbox.scss';
import { getString } from '../../../helpers/styles';
import Bell from '../../../shared/assets/icons/redesign/bell.svg';
import Inbox from '../../../shared/assets/icons/redesign/inbox.svg';
import { trackAction, usePlatform } from '../../../helpers/tracking';
import Settings from '../../../shared/assets/icons/redesign/settings.svg';
import ChevronRight from '../../../shared/assets/icons/redesign/chevron-right.svg';

import { ListItem } from '../../InboxList/InboxList';

export default function UserInbox({
  NextLink,
  taskList,
  addUserEvent,
  markNotificationRead,
  notificationList = [],
  userInboxNotificationsRefetch
}) {
  const router = useRouter();
  const platform = usePlatform();
  const [activeTab, setActiveTab] = useState('notifications');

  useEffect(() => {
    if (router.pathname.startsWith('/inbox'))
      history.pushState({}, '', `/inbox#${activeTab}`);
  }, [activeTab]);

  return (
    <div className="userInbox">
      <div className="userInbox-header">
        <h2 className="userInbox-header-title">Inbox</h2>

        <div>
          <button
            onClick={() => {
              if (activeTab !== 'notifications') return;

              notificationList.forEach((item) => {
                markNotificationRead({ variables: { xuid: item.xuid } });
              });

              Array.prototype.forEach.call(
                document.querySelectorAll('.userInbox-list-item-marker-active'),
                (item) =>
                  item.classList.remove('userInbox-list-item-marker-active')
              );

              document.querySelector('.userInbox-tab-count').textContent = 0;

              trackAction(platform, 'inbox', 'marknotificationsread');
            }}
            className="userInbox-link"
          >
            Alles gelezen
          </button>

          <a href="/profile-settings">
            <Settings className="userInbox-settings-icon" />
          </a>
        </div>
      </div>

      <ul className="userInbox-tabs">
        <li
          className={getString([
            {
              value: 'userInbox-tab',
              condition: true
            },
            {
              value: 'userInbox-tab-active',
              condition: activeTab === 'notifications'
            }
          ])}
          onClick={() => (
            setActiveTab('notifications'),
            trackAction(platform, 'inbox', 'clicknotificationstab')
          )}
        >
          <Bell className="userInbox-tab-icon" />
          <span className="userInbox-tab-label">Notificaties</span>

          <span className="userInbox-tab-count">{notificationList.length}</span>
        </li>

        <li
          className={getString([
            {
              value: 'userInbox-tab',
              condition: true
            },
            {
              value: 'userInbox-tab-active',
              condition: activeTab === 'tasks'
            }
          ])}
          onClick={() => (
            setActiveTab('tasks'),
            userInboxNotificationsRefetch(),
            trackAction(platform, 'inbox', 'clicktaskstab')
          )}
        >
          <Inbox className="userInbox-tab-icon" />
          <span className="userInbox-tab-label">Taken</span>

          <span className="userInbox-tab-count">{taskList.length}</span>
        </li>
      </ul>

      <div className="userInbox-active-tab-header">
        {activeTab === 'notifications' ? (
          <>
            <Bell className="userInbox-active-tab-header-icon" />

            <h3 className="userInbox-active-tab-header-title">Notificaties</h3>
          </>
        ) : (
          <>
            <Inbox className="userInbox-active-tab-header-icon" />

            <h3 className="userInbox-active-tab-header-title">Taken</h3>
          </>
        )}
      </div>

      <ul className="userInbox-list">
        {activeTab === 'notifications'
          ? notificationList.map((item, idx) => (
              <li
                key={item.xuid}
                className="userInbox-list-item"
                onClick={(e) => {
                  addUserEvent({
                    variables: {
                      type: `notification:${item.type.name}:click`,
                      data: '{}'
                    }
                  });

                  const itemMarker = e.target
                    .closest('.userInbox-list-item')
                    .querySelector('.userInbox-list-item-marker');

                  if (
                    itemMarker.classList.contains(
                      'userInbox-list-item-marker-active'
                    )
                  ) {
                    e.target
                      .closest('.userInbox-list-item')
                      .querySelector('.userInbox-list-item-marker')
                      .classList.remove('userInbox-list-item-marker-active');

                    markNotificationRead({ variables: { xuid: item.xuid } });

                    const notificationCountNode = document.querySelector(
                      '.userInbox-tab-count'
                    );

                    if (+notificationCountNode.textContent >= 0)
                      notificationCountNode.textContent =
                        +notificationCountNode.textContent - 1;
                  }

                  trackAction(platform, 'inbox', 'clicknotification');

                  location.href = item.link;
                }}
              >
                <div className="userInbox-list-item-content">
                  <div className="nowrap">
                    <div
                      data-id={idx}
                      className={getString([
                        {
                          value: 'userInbox-list-item-marker',
                          condition: true
                        },
                        {
                          value: 'userInbox-list-item-marker-active',
                          condition: !item.readAt
                        }
                      ])}
                    >
                      &#8226;
                    </div>

                    <p className="userInbox-list-item-title">{item.message}</p>
                  </div>

                  <div className="nowrap">
                    <div className="userInbox-list-item-date-marker">
                      &#8226;
                    </div>

                    <p className="userInbox-list-item-date">
                      {timeSince(new Date(item.createdAt))}
                    </p>

                    <ChevronRight className="userInbox-list-item-icon" />
                  </div>
                </div>
              </li>
            ))
          : taskList.map((item) => (
              <ListItem
                item={item}
                key={item.xuid}
                NextLink={NextLink}
                onClick={() => trackAction(platform, 'inbox', 'clicktask')}
              />
            ))}
      </ul>
    </div>
  );
}

function timeSince(date) {
  const now = new Date();

  const seconds =
    Math.floor((now - date) / 1000) + now.getTimezoneOffset() * 60 + 60 * 60;

  let interval = seconds / 31536000;

  if (interval > 1) return `${Math.floor(interval)}j`;

  interval = seconds / 2592000;

  if (interval > 1) return `${Math.floor(interval)}ma`;

  interval = seconds / 86400;

  if (interval > 1) return `${Math.floor(interval)}d`;

  interval = seconds / 3600;

  if (interval > 1) return `${Math.floor(interval)}u`;

  interval = seconds / 60;

  if (interval > 1) return `${Math.floor(interval)}m`;

  return `${Math.floor(interval)}s`;
}
