import React, { Fragment, useState } from 'react';

import './InboxList.scss';
import { useMobile } from '../../helpers/hooks';
import { getString } from '../../helpers/styles';
import sendEvent from '../../helpers/tracking';
import ChevronRight from '../../shared/assets/icons/redesign/chevron-right.svg';
import ChevronDown from '../../shared/assets/icons/redesign/chevron-down.svg';
import ChevronUp from '../../shared/assets/icons/redesign/chevron-up.svg';
import Inbox from '../../shared/assets/icons/redesign/inbox.svg';

export default function InboxList({ list, user, NextLink }) {
  const [isMobile] = useMobile();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="inboxList">
      {isMobile ? (
        <Fragment>
          <div
            className={getString([
              {
                condition: true,
                value: 'inboxList-header'
              },
              {
                condition: isOpen,
                value: 'inboxList-header--opened'
              }
            ])}
            onClick={() => list.length && setIsOpen(!isOpen)}
          >
            <div>
              <Inbox className="inboxList-inbox-icon" />

              <h4 className="inboxList-title">Taken</h4>

              <div className="inboxList-length">{list.length}</div>
            </div>

            <div>
              <ChevronUp className="inboxList-chevron-up-icon" />
              <ChevronDown className="inboxList-chevron-down-icon" />
            </div>
          </div>

          <List
            list={list}
            user={user}
            isOpen={isOpen}
            NextLink={NextLink}
            isMobile={isMobile}
          />
        </Fragment>
      ) : (
        <Fragment>
          <h4 className="inboxList-title">
            Taken
            {!list.length && (
              <span className="inboxList-length-desktop inboxList-length">
                0
              </span>
            )}
          </h4>

          <List
            list={list}
            user={user}
            NextLink={NextLink}
            isMobile={isMobile}
          />
        </Fragment>
      )}
    </div>
  );
}

function List({ list, isOpen, isMobile, NextLink }) {
  return (
    <ul
      className="inboxList-list"
      {...(isOpen && { style: { maxHeight: `${6 * list.length}rem` } })}
    >
      {list.map((item) => (
        <ListItem
          item={item}
          key={item.xuid}
          NextLink={NextLink}
          isMobile={isMobile}
        />
      ))}
    </ul>
  );
}

export function ListItem({ item, NextLink, isMobile, onClick }) {
  return (
    <NextLink
      prefetch={false}
      href={
        item.form
          ? `/form-answers/${item.xuid}`
          : `/form-answers/create/${item.xuid}/${item.recipient}`
      }
    >
      <li
        className="inboxList-list-item"
        onClick={() => {
          if (onClick) onClick();

          if (isMobile)
            sendEvent(
              ...(isMobile
                ? ['dashboard-partner-mobile', 'dashboard-partner-mobile-inbox']
                : [
                  'dashboard-partner-desktop',
                  'dashboard-partner-desktop-inbox'
                ])
            );
        }}
      >
        <div className="inboxList-list-item-text">
          <div className="inboxList-list-item-marker">&#8226;</div>

          <div>
            <p className="inboxList-list-item-title">
              {item.form?.name || item.name}
            </p>

            {item.recipient && (
              <span className="inboxList-list-item-subtitle">
                {item.recipient[0].toUpperCase() + item.recipient.slice(1)}
              </span>
            )}
          </div>
        </div>

        <ChevronRight className="inboxList-list-item-icon" />
      </li>
    </NextLink>
  );
}
